<template>
 <div class="vip-content">
    <div class="cont-header">
        <span>开通记录</span>
    </div>
    <div class="cont">
        <a-row v-for="(scope, key) in dataTableList" :key="key" style="margin-bottom: 20px;">
            <a-col :span="24" class="list-header" style="line-height:64px;background: #F8FAFB;border: 1px solid #EBF0F2;padding-left: 1%;">
                <a-col span="4"><span>VIP类型</span></a-col>
                <a-col span="4"><span>时长</span></a-col>
                <a-col span="4"><span>开通时间</span></a-col>
                <a-col span="4"><span>有效期</span></a-col>
                <a-col span="8">
                    <span>权益说明</span>
                    <img src="@/assets/images/web/music/status_on.svg" style="position: relative;float: right;" v-if="scope.is_enalbe==1" />
                    <img src="@/assets/images/web/music/status_off.svg" style="position: relative;float: right;" v-else>
                </a-col>
            </a-col>
            <a-col :span="24" style="height:130px;border: 1px solid #EBF0F2;display:flex;align-items:center;padding-left: 1%;">
                <a-col :span="4">
                    <img v-if="scope.auth_type==1" src="@/assets/images/web/music/vip_company.svg">
                    <img v-if="scope.auth_type==2" src="@/assets/images/web/music/vip_personal.svg">
                </a-col>
                <a-col :span="4">
                    <span v-if="scope.vip_type==1">月度</span>
                    <span v-if="scope.vip_type==2">季度</span>
                    <span v-if="scope.vip_type==3">年度</span>
                    <span v-if="scope.vip_type==4">定制</span>
                </a-col>
                <a-col :span="4">
                    <span>{{scope.coop_start_date}}</span>
                </a-col>
                <a-col :span="4">
                    <span>{{scope.coop_end_date}}</span>
                </a-col>
                <a-col :span="8" style="text-align:left;">
                    <a-col :span="24">
                        <p v-if="scope.vip_type==4">曲库类型：全部曲库</p>   
                        <p v-if="scope.vip_type!=4">曲库类型：订阅曲库</p>   
                        <p>下载限制：
                            <span v-if="scope.download_num == -1">不限量/天</span>
                            <span v-else>{{scope.download_num}}</span>
                        </p>   
                        <p v-if="scope.auth_type==1">商用说明：可企业商用</p>
                        <p v-if="scope.auth_type==2">商用说明：仅限个人使用，不可企业商用</p>   
                    </a-col>
                </a-col>
            </a-col>
        </a-row>
        <a-row v-if="dataTableList.length == 0" style="text-align:center;padding-top:24px;">
            <img src="@/assets/images/empty.png" style="width:76px;"/>
            <br/><span style="color:rgba(0, 0, 0, 0.25);">暂无数据</span>
        </a-row>
    </div>
</div>
</template>
<script>
    import {Table as ATable} from 'ant-design-vue';
    export default {
        data() {
            return {
                loading: true,
                pagination: {
                    "show_pagin" : true,
                    "show-quick-jumper": true,
                    "default-current":1,
                    total:0,
                    pageSize: 20,
                    current: 0,
                    onChange: this.onChangePage,
                    hideOnSinglePage: true,
                    style:{
                        "float": "none",
                        "text-align": "center",
                    },
                },
                columns:[
                    {
                        title: 'VIP类型',
                        dataIndex: 'vip-type',
                        align:'center',
                        scopedSlots: { customRender: 'vip-type'},
                    },
                    {
                        title: '开通时间',
                        align:'center',
                        dataIndex: 'coop_start_date',
                    },
                    {
                        title: '到期时间',
                        align:'center',
                        dataIndex: 'coop_end_date',
                    },
                    {
                        title: '权限说明',
                        dataIndex: 'auth_scene_name',
                        align:'left',
                        width: '40%',
                        scopedSlots: { customRender: 'auth-info'},
                    },
                ],
                dataTableList:[],
            }
        },
        components: {
            ATable
        },
        created: function () {
            this.getTableData();
        },
        methods: {
            getTableData(params){
                this.$axios.vipgoodsList(params).then(res => {
                    if (res.data.code == 0) {
                        this.dataTableList = res.data.data.list;
                        this.pagination.total = res.data.data.total;
                        this.loading = false;
                    }
                })
            },
            onChangePage(nowpage) {
                this.loading = true;
                let params = {
                    page:nowpage,
                }
                this.getTableData(params);
                this.pagination.current = nowpage;
                this.pagination = Object.assign({}, this.pagination);
            },
        },
    }
</script>
<style lang="scss" scoped>
    .cont-header{
        height: 66px;
        line-height: 66px;
        border-bottom:1px solid #F0F4F5;
        span{
            display: inline-block;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
            height: 22px;
        }
        span:nth-child(1){
            width: 20%;
            padding-left: 32px;
        }
        span:nth-child(2){
            cursor: pointer;
            width: 80%;
            color: #FF7B13;
            text-align: right;
            padding-right:32px;
        }
    }
    .cont{
        width: 90%;
        margin: 0 auto;
        padding-top:24px;
    }
    .auth-info{
        p{
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
        }
        p:nth-child(4){
            color: #333333;
        }
    }
</style>